const fromCI = {
  env: process.env.KEPLR_CLUSTER_ENV,
  version: process.env.KEPLR_APP_VERSION,
  isDev: process.env.KEPLR_CLUSTER_ENV === "DEV",
  isProd: process.env.KEPLR_CLUSTER_ENV === "PROD",
  isLocal: process.env.KEPLR_CLUSTER_ENV === "LOCAL",
  isStaging: process.env.KEPLR_CLUSTER_ENV === "STAGING",
  isEphemeral: false,
  launchDarklyClientID: process.env.KEPLR_LAUNCHDARKLY_CLIENT_ID,
};

const fromCustom = {
  apiUrl: process.env.KEPLR_API_URL,
};

const fromComputed = {
  isLikeDev: fromCI.isLocal || fromCI.isDev || fromCI.isEphemeral,
  isLikeProd: fromCI.isProd || fromCI.isStaging,
};

export const constants = {
  ...fromCI,
  ...fromCustom,
  ...fromComputed,
};
