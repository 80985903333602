import keplr from "~/assets/keplr.png";
import { constants } from "~/constants";

import * as S from "./Splash.styles";

const Splash = () => (
  <div className={S.Wrapper}>
    <img src={keplr} alt="Keplr Logo" />
    <h1>Keplr Pickup</h1>
    <h2>by Keplr</h2>
    <span className={S.Version}>v{constants.version}</span>{" "}
  </div>
);

export { Splash };
