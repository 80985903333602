// Types are not exposed publicly.
import { constants } from "~/constants";
type NetworkQuery = [RequestInfo, RequestInit?];
type NetworkError = { code?: string; message: string };
type NetworkResponse<T> = Promise<{ data?: T; error?: NetworkError }>;
type BackendError = { code?: string; error: string; message: string; statusCode: number };

/** This is the base url for all API requests */
const ApiUrl = constants.apiUrl || `${location.protocol}//api.${location.host}`;

/** Common header for all requests */
const headers = { "Content-type": "application/json; charset=UTF-8" };

/** Typeguard against back-end errors */
const isBackendError = (data: unknown): data is BackendError => {
  if (typeof data !== "object" || data == null) return false;
  return "error" in data && "message" in data && "statusCode" in data;
};

/** Wrap fetch to get typed promises */
const request = async <T>(...args: NetworkQuery): NetworkResponse<T> => {
  try {
    const response = await fetch(...args);
    const data: unknown = await response.json();
    if (!isBackendError(data)) return { data } as { data: T };
    return { error: { code: data.code, message: data.message } };
  } catch (error: unknown) {
    const { message } = error as NetworkError;
    return { error: { message } };
  }
};

/** Execute a GET request */
const get = <T>(path: string, params?: Record<string, string>) => {
  const url = `${ApiUrl}${path}?${new URLSearchParams(params).toString()}`;
  return request<T>(url, { method: "GET", headers });
};

/** Execute a POST request */
const put = <T, U = unknown>(path: string, payload?: U) => {
  const url = `${ApiUrl}${path}`;
  const body = payload && JSON.stringify(payload);
  return request<T>(url, { method: "PUT", headers, body });
};

/** Execute a POST request */
const post = <T, U = unknown>(path: string, payload?: U) => {
  const url = `${ApiUrl}${path}`;
  const body = payload && JSON.stringify(payload);
  return request<T>(url, { method: "POST", headers, body });
};

/** Execute a PATCH request */
const patch = <T, U = unknown>(path: string, payload?: U) => {
  const url = `${ApiUrl}${path}`;
  const body = payload && JSON.stringify(payload);
  return request<T>(url, { method: "PATCH", headers, body });
};

/** Container for the network service */
const Network = { get, put, post, patch };
export { Network };
