import { constants } from "~/constants";
import { Logger } from "~/services/Logger";

if (!constants.isLocal)
  void (async () => {
    // Create message queue
    const queue: (() => void)[] = [];
    Logger.info = (msg) => queue.push(() => Logger.info(msg));
    Logger.warn = (msg) => queue.push(() => Logger.warn(msg));
    Logger.error = (error) => queue.push(() => Logger.error(error));

    // Lazy-load Sentry and initialize it
    const Sentry = await import("@sentry/browser");
    Sentry.init({
      dsn: "https://b77f2df642e34691b1bc6abfe53662e2@o180686.ingest.sentry.io/5398023",
      release: constants.isLikeProd
        ? constants.version
        : `dev-${constants.version?.substr(0, 8) ?? ""}`,
      environment: constants.env,
    });

    // Upgrade Logger using Sentry and flush the queue
    Logger.info = (msg) => void Sentry.captureMessage(msg, Sentry.Severity.Info);
    Logger.warn = (msg) => void Sentry.captureMessage(msg, Sentry.Severity.Warning);
    Logger.error = (error) => void Sentry.captureException(error);
    while (queue.length > 0) queue.shift()?.();
  })();

export {};
